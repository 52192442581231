import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useProductContext } from "./context/ProductContext";
import PageNavigation from "./components/PageNavigation";
import MyImage from "./components/MyImage";
import { Container } from "./styles/Container";
import FormatPrice from "./Helpers/FormatPrice";
import { TbTruckDelivery, TbReplace } from "react-icons/tb";
import AddToCart from "./components/AddToCart";
import styled, { keyframes } from "styled-components";
import FeatureProduct from "./components/FeatureProduct";
import OutOfStockMessage from "./components/OutOfStockMessage";
import ImageSliderPopup from "./components/ImageSliderPopup";
import { GoPackageDependents } from "react-icons/go";

// API URL
const API =
  "https://productapi-hamzaretroconsoles-express-iwuv.onrender.com/api/products";

// Define the keyframe animation for loading
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SingleProduct = () => {
  const { state, getSingleProduct } = useProductContext();
  const singleProduct = state.singleProduct;
  const isSingleLoading = state.isSingleLoading;
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open and close the modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const {
    iD: alias,
    title,
    description,
    company,
    images,
    quantity,
    price,
  } = singleProduct;

  const imageRef = useRef(null);

  const [scrollDone, setScrollDone] = useState(false);

  // Load the product on page load
  useEffect(() => {
    getSingleProduct(`${API}/${id}`);
  }, [id]);

  // Trigger scroll to the top image after page load
  useEffect(() => {
    const timer = setTimeout(() => {
      if (imageRef.current && !scrollDone) {
        window.scrollTo({
          top: imageRef.current.offsetTop,
          behavior: "smooth",
        });
        setScrollDone(true); // Ensure we scroll only once
      }
    }, 500); // Adjust this timeout as needed

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [singleProduct, scrollDone]);

  // Function to handle product click from FeatureProduct
  const handleFeatureProductClick = (productId) => {
    setScrollDone(false); // Reset scroll flag
    navigate(`/product/${productId}`); // Navigate to the product page
  };

  if (isSingleLoading) {
    return (
      <LoadingWrapper>
        <div className="page-loading">Loading...</div>
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <PageNavigation title={title} />
      <Container className="container">
        <div className="grid grid-two-column">
          <div className="product_images" ref={imageRef}>
            <MyImage imgs={images} />
          </div>

          <div className="product-data">
            <h2>{title}</h2>

            <p className="product-data-real-price">
              Price : <FormatPrice price={price} />
            </p>
            <div
              style={{ whiteSpace: "pre-line", lineHeight: "1.6" }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>

            {/* Customer Reviews Slider Pop Up */}
            <ImageSlider>
              <ImageSliderPopup />
            </ImageSlider>

            <div className="product-data-warranty">
              <div className="product-warranty-data">
                <TbTruckDelivery className="warranty-icon" />
                <p>Cash on Delivery</p>
              </div>

              <div className="product-warranty-data">
                <TbReplace className="warranty-icon" />
                <p>
                  14 Days returns&nbsp;
                  <span
                    onClick={toggleModal}
                    style={{ color: "#3a98fe", cursor: "pointer" }}
                  >
                    T&C Apply
                  </span>
                </p>
              </div>

              <div className="product-warranty-data">
                <GoPackageDependents className="warranty-icon" />
                <p>Order Tracking</p>
              </div>
            </div>

            <div className="product-data-info">
              <p>
                Available:
                <span> {quantity > 0 ? "In Stock" : "Not Available"}</span>
              </p>
              <p>
                ID : <span> {id} </span>
              </p>
              <p>
                Brand :<span> {company} </span>
              </p>
            </div>
            <hr />
            <div className="add-to-cart-container">
              {quantity > 0 ? (
                <AddToCart product={singleProduct} />
              ) : (
                <OutOfStockMessage productName={singleProduct} /> // Render this if the product is out of stock
              )}
            </div>
          </div>
        </div>
      </Container>
      <FeatureProduct onProductClick={handleFeatureProductClick} />

      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <h3>Terms and Conditions</h3>
            <p>
              <strong>
                Here are the detailed terms and conditions for returns...
              </strong>
            </p>
            <ul>
              <li>
                <strong>
                  1. 📷 Condition video will be sent after order placement on
                  WhatsApp.
                </strong>
              </li>
              <li>
                <strong>
                  2. ✅ After confirmation of condition, the device cannot be
                  returned based on condition.
                </strong>
              </li>
              <li>
                <strong>
                  3. 🔄 Replacement will be made if the device fails to work
                  without damage or water damage.
                </strong>
              </li>
              <li>
                <strong>4. 🚫 Change of mind is not applicable.</strong>
              </li>
            </ul>
            <ModalButton onClick={toggleModal}>Close</ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </Wrapper>
  );
};

// Wrapper styling
// Wrapper styling
const Wrapper = styled.section`
  .container {
    padding: 9rem 0;
    font-family: "Roboto", sans-serif;
  }

  .product_images {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }

  .product_images:hover {
    transform: scale(1.05);
  }

  .product-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    .product-data-real-price {
      font-weight: bold;

      color: #ff6347;
      font-size: 2.2rem;
      align-self: center;
    }
  }

  .product-data:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }

  .product-data-warranty {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
  }

  .product-warranty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .warranty-icon {
      background-color: transparent; /* Remove solid background */
      border-radius: 50%;
      padding: 0.8rem; /* Adjust padding for appropriate size */
      font-size: 3.5rem; /* Increase icon size if needed */
      margin-bottom: 0.5rem;
      transition: all 0.3s ease;
    }

    .warranty-icon:hover {
      background-color: #3a98fe; /* Retain hover effect */
      color: white;
    }

    p {
      font-size: 1.4rem;
      font-weight: bold;
      color: #555;
    }
  }

  .product-data-info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 1.5rem;
    color: #555;
  }

  .product-data-info span {
    font-weight: bold;
    color: #333;
  }

  hr {
    width: 90%;
    margin: 2rem 0;
    border: 0.1rem solid #ccc;
  }

  /* Center the AddToCart button */
  .add-to-cart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  h2 {
    text-align: center;
    font-weight: bold;
  }

  .product-images {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0 2.4rem;

    .product-data-info {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      font-size: 1.5rem;
      color: #555;
    }

    hr {
      width: 100%; /* Ensure the separator spans the full width on mobile */
    }
  }
`;

// Loading state wrapper styling
const LoadingWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .page-loading {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.text};
    animation: ${rotate} 1s linear infinite;
    font-weight: bold;
    color: #ff6347;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  h3 {
    font-size: 2.2rem; // Increase font size for header
    color: #333;
    font-weight: 700; // Make header bolder
    margin-bottom: 1.5rem;
    text-align: center;
  }

  p {
    font-size: 1.6rem; // Increase font size
    color: #555;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    font-weight: 500; // Make the text bolder
  }

  ul {
    font-size: 1.6rem; // Increase font size for list items
    color: #555;
    line-height: 1.8;
    padding-left: 20px;
    list-style-type: disc;
    margin-bottom: 1.5rem;
    font-weight: 500; // Make list items bolder
  }

  li {
    margin-bottom: 1rem;
  }

  /* Center the button within the modal content */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 0.8rem 2rem;
  font-size: 1.6rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 200px;
  margin-top: 2rem;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const ImageSlider = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
`;

export default SingleProduct;
