import React, { useState } from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import CartAmountToggle from "./CartAmountToggle";
import { NavLink } from "react-router-dom";
import { Button } from "../styles/Button";
import { useCartContext } from "../context/CartContext";

const AddToCart = ({ product }) => {
  const { addToCart } = useCartContext();
  const { _id, title, color, quantity: availableQuantity } = product;

  const [amount, setAmount] = useState(1);

  const handleIncrease = () => {
    if (amount < availableQuantity) {
      setAmount((prevAmount) => prevAmount + 1);
    }
  };

  const handleDecrease = () => {
    if (amount > 1) {
      setAmount((prevAmount) => prevAmount - 1);
    }
  };

  const handleAddToCart = () => {
    addToCart(_id, title, amount, color, product);
  };

  return (
    <Wrapper>
      <CartAmountToggle
        quantity={amount}
        setIncrease={handleIncrease}
        setDecrease={handleDecrease}
      />
      <NavLink to="/Cart">
        <Button onClick={handleAddToCart} className="add-to-cart-btn">
          Add To Cart
        </Button>
      </NavLink>
      <p className="stock-warning">
        Only <span>{availableQuantity} left</span> in stock – order soon!
      </p>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  .stock-warning {
    margin-top: 0.5rem;
    font-size: 1.2rem;
    color: #d9534f;
    font-weight: bold;
  }

  .add-to-cart-btn {
    padding: 1rem 2rem;
    background-color: ${({ theme }) => theme.colors.btn};
    color: white;
    font-size: 1.6rem;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.98);
    }
  }
`;

export default AddToCart;
