import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import FormatPrice from "../Helpers/FormatPrice";
import styled from "styled-components";

const Product = ({ _id, title, images, price }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <NavLink to={`/singleproduct/${_id}`}>
      <Card>
        <figure>
          {/* Show Loader While Image is Loading */}
          {isLoading && <SkeletonLoader />}

          <img
            src={images[0]}
            alt={title}
            onLoad={() => setIsLoading(false)}
            style={{ display: isLoading ? "none" : "block" }}
          />

          <figcaption className="caption">
            <PriceWrapper>
              <FormatPrice price={price} />
            </PriceWrapper>
          </figcaption>
        </figure>
        <CardData>
          <label>{title}</label>
        </CardData>
      </Card>
    </NavLink>
  );
};

// Skeleton Loader Component (Shimmer Effect)
const SkeletonLoader = styled.div`
  width: 100%;
  height: 200px; /* Adjust to your image size */
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 10px;

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
`;

const Card = styled.div``;

const CardData = styled.div`
  text-align: center;
  margin-top: 10px;

  label {
    font-weight: bold;
    font-size: 2.2rem;
    color: #444;
  }
`;

const PriceWrapper = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  color: rgb(234, 120, 6);
  margin-top: 5px;
`;

export default Product;
