import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useSwipeable } from "react-swipeable";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Import arrow icons

const MyImage = ({ imgs = [{ url: "" }] }) => {
  const [mainImage, setMainImage] = useState(imgs[0]);
  const [isZoomed, setIsZoomed] = useState(false); // Zoom state
  const [isHovered, setIsHovered] = useState(false); // Track hover state for arrows

  // Swipe handlers for mobile
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => nextImage(),
    onSwipedRight: () => previousImage(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // Navigate to the next image
  const nextImage = () => {
    const currentIndex = imgs.indexOf(mainImage);
    const nextIndex = (currentIndex + 1) % imgs.length;
    setMainImage(imgs[nextIndex]);
  };

  // Navigate to the previous image
  const previousImage = () => {
    const currentIndex = imgs.indexOf(mainImage);
    const previousIndex = (currentIndex - 1 + imgs.length) % imgs.length;
    setMainImage(imgs[previousIndex]);
  };

  // Toggle zoom on the main image
  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <Wrapper>
      {/* Thumbnail Grid */}
      <div className="thumbnail-grid">
        {imgs.map((curElm, index) => (
          <div
            key={index}
            className={`thumbnail ${curElm === mainImage ? "active" : ""}`}
            onClick={() => setMainImage(curElm)}
          >
            <img src={curElm} alt={`Thumbnail ${index + 1}`} loading="lazy" />
          </div>
        ))}
      </div>

      {/* Main Image with Swipe, Zoom, and Arrows */}
      <div
        className="main-image"
        {...swipeHandlers}
        onMouseEnter={() => setIsHovered(true)} // Show arrows on hover
        onMouseLeave={() => setIsHovered(false)} // Hide arrows when not hovered
      >
        {/* Left Arrow */}
        <div
          className="arrow-button left"
          onClick={previousImage}
          title="Previous Image"
          style={{ display: isHovered ? "flex" : "none" }} // Show only on hover
        >
          <FaChevronLeft size={24} color="#FFF" />
        </div>

        {/* Right Arrow */}
        <div
          className="arrow-button right"
          onClick={nextImage}
          title="Next Image"
          style={{ display: isHovered ? "flex" : "none" }} // Show only on hover
        >
          <FaChevronRight size={24} color="#FFF" />
        </div>

        {/* Main Image */}
        <img
          src={mainImage}
          alt="Main Product"
          onClick={toggleZoom}
          className={isZoomed ? "zoomed" : ""}
          loading="lazy"
        />

        {/* Zoom Overlay */}
        {isZoomed && (
          <div className="zoom-overlay" onClick={toggleZoom}>
            <p>Click anywhere to exit zoom</p>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

// Keyframes for pulse animation
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  gap: 1rem;

  /* Thumbnail Grid */
  .thumbnail-grid {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    gap: 1rem;

    .thumbnail {
      cursor: pointer;
      border: 2px solid transparent;
      border-radius: 8px;
      overflow: hidden;
      transition: all 0.3s ease;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        border-color: #007bff;
        transform: scale(1.05);
      }

      &.active {
        border-color: #007bff;
      }
    }
  }

  /* Main Image */
  .main-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;

    img {
      max-width: 100%;
      max-height: 80vh;
      object-fit: contain;
      cursor: zoom-in;
      transition: transform 0.3s ease;

      &.zoomed {
        transform: scale(2);
        cursor: zoom-out;
      }
    }

    .zoom-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 1.2rem;
      cursor: zoom-out;
    }

    /* Arrow Buttons */
    .arrow-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1001;
      animation: ${pulse} 2s infinite;

      &.left {
        left: 3px;
      }

      &.right {
        right: 3px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  /* Responsive Styles */
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    .thumbnail-grid {
      grid-template-rows: 1fr;
      grid-template-columns: repeat(4, 1fr);
    }

    .main-image img.zoomed {
      transform: scale(1.5); // Adjust zoom level for mobile
    }

    .arrow-button {
      display: flex !important; // Always show arrows on mobile
    }
  }
`;

export default MyImage;
