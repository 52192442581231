import styled, { keyframes } from "styled-components";
import { useState, useEffect } from "react";
import { useCartContext } from "./context/CartContext";
import CartItem from "./components/CartItem";
import { NavLink } from "react-router-dom";
import { Button } from "./styles/Button";
import { RiCashLine } from "react-icons/ri";
import {
  FaLock,
  FaShippingFast,
  FaMoneyBillWave,
  FaCreditCard,
  FaTrash,
} from "react-icons/fa";
import FormatPrice from "./Helpers/FormatPrice";
import axios from "axios";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const cities = [
  "Karachi",
  "Lahore",
  "Islamabad",
  "Rawalpindi",
  "Faisalabad",
  "Multan",
  "Gujranwala",
  "Quetta",
  "Peshawar",
  "Hyderabad",
  "Sialkot",
  "Gujrat",
  "Bahawalpur",
  "Sargodha",
  "Sukkur",
  "Jhang",
  "Sheikhupura",
  "Mardan",
  "Larkana",
  "Kasur",
  "Abbottabad",
  "Murree",
  "Chakwal",
  "Sahiwal",
  "Vehari",
  "Okara",
  "Dera Ghazi Khan",
  "Muzaffarabad",
  "Mirpur",
  "Mingora",
  "Nowshera",
  "Mansehra",
  "Attock",
  "Bhakkar",
  "Rahim Yar Khan",
  "Toba Tek Singh",
  "Kohat",
  "Chiniot",
  "Swat",
  "Hafizabad",
  "Nankana Sahib",
  "Khushab",
  "Dera Ismail Khan",
  "Charsadda",
  "Mandi Bahauddin",
  "Jhelum",
  "Khuzdar",
  "Jacobabad",
  "Kandhkot",
  "Hub",
  "Turbat",
  "Zhob",
  "Gwadar",
  "Shikarpur",
  "Mirpur Khas",
  "Nawabshah",
  "Badin",
  "Thatta",
  "Hala",
  "Skardu",
  "Gilgit",
  "Chilas",
  "Hunza",
  "Khaplu",
  "Diamer",
  "Ghotki",
  "Umerkot",
  "Tando Adam",
  "Kambar",
  "Dadu",
  "Shahdadpur",
  "Tando Allahyar",
  "Moro",
  "Layyah",
  "Rajanpur",
  "Lodhran",
  "Narowal",
  "Muzafargarh",
  "Kharian",
  "Haripur",
  "Tank",
  "Chitral",
  "Parachinar",
  "Bannu",
  "Hangu",
  "Buner",
  "Shangla",
  "Malakand",
  "Timergara",
  "Upper Dir",
  "Lower Dir",
  "Ziarat",
  "Kalat",
  "Sibi",
  "Dalbandin",
  "Pasni",
  "Ormara",
  "Surab",
  "Panjgur",
  "Dera Allah Yar",
  "Usta Mohammad",
  "Mach",
  "Sui",
  "Gadani",
  "Jiwani",
  "Tando Muhammad Khan",
];

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Cart = () => {
  // Scroll to the top when the component is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const { cart, clearCart, total_price, shipping_fee } = useCartContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    mobile: "",
    email: "",
    city: "",
    address: "",
  });
  const [errors, setErrors] = useState({});
  const [isOrderPlaced, setIsOrderPlaced] = useState(false); // State to manage success message

  useEffect(() => {
    if (isOrderPlaced) {
      setIsModalOpen(true); // set the modal to true the order is placed
    }
  }, [isOrderPlaced]);

  ///Function to toggle order confirmation popup
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
    }

    if (
      !formData.email.trim() ||
      !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
        formData.email.trim()
      )
    ) {
      errors.email = "Please enter a valid email address";
    }

    if (!formData.mobile.trim() || !/^03\d{9}$/.test(formData.mobile.trim())) {
      errors.mobile = "Please enter a valid Pakistani mobile number";
    }

    if (!formData.city.trim()) {
      errors.city = "City is required";
    }

    if (!formData.address.trim()) {
      errors.address = "Address is required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const cartData = cart.map((item) => ({
          id: item.id,
          title: item.title,
          color: item.color,
          quantity: item.quantity,
          price: item.price,
        }));

        const orderData = {
          ...formData,
          cart: cartData,
          totalAmount: total_price + shipping_fee,
        };

        const api = axios.create({
          baseURL:
            "https://productapi-hamzaretroconsoles-express-iwuv.onrender.com/api/",
        });

        const response = await api.post("orders/save-order", {
          ...formData,
          cart,
          totalAmount: total_price + shipping_fee,
        });

        if (response.data.message) {
          setIsOrderPlaced(true);

          await Promise.all(
            cart.map((item) =>
              api.patch(`products/update-quantity/${item.id}`, {
                quantity: item.quantity,
              })
            )
          );

          clearCart();

          setFormData({
            fullName: "",
            mobile: "",
            email: "",
            city: "",
            address: "",
          });

          setTimeout(() => {
            setIsOrderPlaced(false);
          }, 15000);
        } else {
          alert("Failed to place order");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Failed to place order");
      }
    }
  };

  if (cart.length === 0) {
    return (
      <EmptyDiv>
        <h3>No Item in Cart</h3>
        <NavLink to="/products">
          <Button> Return To Products </Button>
        </NavLink>
      </EmptyDiv>
    );
  }

  return (
    <>
      <Wrapper>
        <div className="container">
          <div className="cart_heading grid grid-five-column">
            <p>Item</p>
            <p className="cart-hide">Price</p>
            <p>Quantity</p>
            <p className="cart-hide">Subtotal</p>
            <p>Remove</p>
          </div>
          <hr />

          <div className="cart-item">
            {cart.map((curElem) => {
              return <CartItem key={curElem.id} {...curElem} />;
            })}
          </div>
          <div className="cart-two-button">
            <NavLink to="/products">
              <Button> continue Shopping </Button>
            </NavLink>
            <Button className="btn btn-clear" onClick={clearCart}>
              clear cart
            </Button>
          </div>

          {/* order total_amount */}
          <OrderSummary>
            <h2>Order Summary</h2>
            <SummaryItem>
              <span>Items ({cart.length})</span>
              <span>
                <FormatPrice price={total_price} />
              </span>
            </SummaryItem>
            <SummaryItem>
              <span>Shipping</span>
              <span>
                <FormatPrice price={shipping_fee} />
              </span>
            </SummaryItem>
            <SummaryItem highlight>
              <span>Total</span>
              <span>
                <FormatPrice price={shipping_fee + total_price} />
              </span>
            </SummaryItem>

            <TrustBadges>
              <TrustItem>
                <FaLock />
                <span>Secure Checkout</span>
              </TrustItem>
              <TrustItem>
                <FaShippingFast />
                <span>Fast Delivery</span>
              </TrustItem>
              <TrustItem>
                <FaMoneyBillWave />
                <span>14 days returns T&C</span>
              </TrustItem>
            </TrustBadges>
          </OrderSummary>
          <hr />
        </div>
      </Wrapper>
      {/* Address Form */}
      <AddressFormContainer>
        <h2 className="form-title">Enter Your Delivery Address</h2>
        <form onSubmit={handleSubmit} className="address-form">
          {/* Full Name */}
          <div className="form-group">
            <label htmlFor="fullName">Full Name *</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Your Name"
            />
            {errors.fullName && (
              <span className="error">{errors.fullName}</span>
            )}
          </div>

          {/* Email */}
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="youremail@example.com"
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>

          {/* Mobile Number */}
          <div className="form-group">
            <label htmlFor="mobile">Mobile *</label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="03XXXXXXXXX"
            />
            {errors.mobile && <span className="error">{errors.mobile}</span>}
          </div>

          {/* City */}
          <div className="form-group">
            <label htmlFor="city">City *</label>
            <select
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
            >
              <option value="">Select City</option>
              {cities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
            {errors.city && <span className="error">{errors.city}</span>}
          </div>

          {/* Address */}
          <div className="form-group">
            <label htmlFor="address">Address *</label>
            <textarea
              id="address"
              name="address"
              rows="4"
              value={formData.address}
              onChange={handleChange}
              placeholder="House #, Street, Area"
            ></textarea>
            {errors.address && <span className="error">{errors.address}</span>}
          </div>
          <PaymentSection>
            <h3>Payment Method</h3>
            <PaymentOptions>
              <PaymentOption>
                <input type="radio" id="cod" name="payment" defaultChecked />
                <label htmlFor="cod">
                  <RiCashLine /> Cash on Delivery
                </label>
              </PaymentOption>
              <PaymentOption disabled>
                <input type="radio" id="card" name="payment" disabled />
                <label htmlFor="card">
                  <FaCreditCard /> Credit/Debit Card (Coming Soon)
                </label>
              </PaymentOption>
            </PaymentOptions>
          </PaymentSection>
          {/* Submit Button */}
          <button type="submit" className="submit-btn">
            Place Order
          </button>
        </form>
        <SecurityNote>
          <FaLock /> Your information is securely stored
        </SecurityNote>
      </AddressFormContainer>

      {/* Order Placed PopUp */}
      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <h2>Order Placed. ✅</h2>
            <h3>
              You will recieve a confirmation call on your provided mobile no.
              Shortly.
            </h3>
            <ModalButton onClick={toggleModal}>Close</ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

const EmptyDiv = styled.div`
  display: grid;
  place-items: center;
  height: 50vh;

  h3 {
    font-size: 4.2rem;
    text-transform: capitalize;
    font-weight: 300;
  }
`;

const Wrapper = styled.section`
  padding: 9rem 0;

  .grid-four-column {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid-five-column {
    grid-template-columns: repeat(4, 1fr) 0.3fr;
    text-align: center;
    align-items: center;
  }
  .cart-heading {
    text-align: center;
    text-transform: uppercase;
  }
  hr {
    margin-top: 1rem;
  }
  .cart-item {
    padding: 3.2rem 0;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
  }

  .cart-user--profile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 5.4rem;

    img {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
    }
    h2 {
      font-size: 2.4rem;
    }
  }
  .cart-user--name {
    text-transform: capitalize;
  }
  .cart-image--name {
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: 0.4fr 1fr;
    text-transform: capitalize;
    text-align: left;
    img {
      max-width: 5rem;
      height: 5rem;
      object-fit: contain;
      color: transparent;
    }

    .color-div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      .color-style {
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
      }
    }
  }

  .cart-two-button {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    .btn-clear {
      background-color: #e74c3c;
    }
  }

  .amount-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
    font-size: 1.4rem;

    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
    }

    .amount-style {
      font-size: 2.4rem;
      color: ${({ theme }) => theme.colors.btn};
    }
  }

  .remove_icon {
    font-size: 1.6rem;
    color: #e74c3c;
    cursor: pointer;
  }

  .order-total--amount {
    width: 100%;
    margin: 4.8rem 0;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .order-total--subdata {
      border: 0.1rem solid #f0f0f0;
      display: flex;
      flex-direction: column;
      gap: 1.8rem;
      padding: 3.2rem;
    }
    div {
      display: flex;
      gap: 3.2rem;
      justify-content: space-between;
    }

    div:last-child {
      background-color: #fafafa;
    }

    div p:last-child {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.heading};
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-five-column {
      grid-template-columns: 1.5fr 1fr 0.5fr;
    }
    .cart-hide {
      display: none;
    }

    .cart-two-button {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      gap: 2.2rem;
    }

    .order-total--amount {
      width: 100%;
      text-transform: capitalize;
      justify-content: flex-start;
      align-items: flex-start;

      .order-total--subdata {
        width: 100%;
        border: 0.1rem solid #f0f0f0;
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
        padding: 3.2rem;
      }
    }
  }
`;

const AddressFormContainer = styled.div`
  margin-top: 4rem;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .form-title {
    font-size: 6rem;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: bold;
    color: #333;
  }

  .address-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .form-group {
    width: 100%;
    max-width: 500px;
    margin-bottom: 1.5rem;
  }

  .form-group label {
    font-size: 2.1rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem; /* Default margin for larger screens */
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 0.8rem;
    font-size: 1.3rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    color: #333;
    transition: border-color 0.3s ease;
  }

  .form-group input:focus,
  .form-group select:focus,
  .form-group textarea:focus {
    border-color: #007bff;
    outline: none;
  }

  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }

  .submit-btn {
    width: 100%;
    max-width: 500px;
    padding: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }

  .submit-btn:hover {
    background-color: #0056b3;
  }

  .error {
    color: red;
    font-size: 0.9rem;
    margin-top: 0.4rem;
    font-weight: 500;
  }

  /* Mobile adjustments */
  @media (max-width: 600px) {
    .form-title {
      font-size: 2.6rem;
    }

    .form-group {
      max-width: 100%;
      margin-bottom: 1rem;
    }

    .form-group label {
      font-size: 1.8rem;
      margin-bottom: 1.8rem; /* Increased margin for mobile */
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
      font-size: 1.5rem;
      padding: 0.7rem;
    }

    .submit-btn {
      padding: 0.9rem;
      font-size: 2rem;
    }
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  h3 {
    font-size: 2.2rem; // Increase font size for header
    color: #333;
    font-weight: 700; // Make header bolder
    margin-bottom: 1.5rem;
    text-align: center;
  }

  p {
    font-size: 1.6rem; // Increase font size
    color: #555;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    font-weight: 500; // Make the text bolder
  }

  ul {
    font-size: 1.6rem; // Increase font size for list items
    color: #555;
    line-height: 1.8;
    padding-left: 20px;
    list-style-type: disc;
    margin-bottom: 1.5rem;
    font-weight: 500; // Make list items bolder
  }

  li {
    margin-bottom: 1rem;
  }

  /* Center the button within the modal content */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 0.8rem 2rem;
  font-size: 1.6rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 200px;
  margin-top: 2rem;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const OrderSummary = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin: 2rem 0;
  position: sticky;
  top: 2rem;
  h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #eee;
    padding-bottom: 1rem;
  }
`;

const SecurityNote = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #27ae60;
  font-size: 1.2rem;
  margin-top: 1rem;
`;

const PaymentSection = styled.div`
  background: #f8f9fa;
  border-radius: 8px;
  padding: 2rem; // Increased padding
  margin: 2rem 0;

  h3 {
    font-size: 2rem; // Increased font size
    margin-bottom: 1.5rem; // Increased margin
    color: #2c3e50;
  }
`;

const PaymentOptions = styled.div`
  display: grid;
  gap: 1.5rem; // Increased gap
`;

const PaymentOption = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem; // Increased padding
  background: #fff;
  border-radius: 8px;
  border: 1px solid ${(props) => (props.disabled ? "#eee" : "#3498db")};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  input {
    margin-right: 1rem;
    transform: scale(1.5); // Increased radio button size
  }

  label {
    display: flex;
    align-items: center;
    gap: 1rem; // Increased gap
    font-size: 1.6rem; // Increased font size
  }

  svg {
    font-size: 2rem; // Increased icon size
  }
`;

const TrustBadges = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
`;

const TrustItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  color: #3498db;

  svg {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
`;

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  color: ${(props) => (props.highlight ? "#e74c3c" : "#7f8c8d")};
  font-weight: ${(props) => (props.highlight ? "bold" : "normal")};
  font-size: ${(props) => (props.highlight ? "1.8rem" : "1.5rem")};
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 0.5rem;
  padding: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  .success-icon {
    animation: ${rotate} 1s linear infinite;
    font-size: 2rem;
  }

  p {
    font-size: 1.6rem;
    font-weight: bold;
  }
`;

export default Cart;
