import React, { useState } from "react";
import { Button } from "../styles/Button";
import { useSwipeable } from "react-swipeable";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Import arrow icons
import { ImSpinner8 } from "react-icons/im"; // Import spinner icon

const ImageSliderPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // For slide transitions
  const [isImageLoading, setIsImageLoading] = useState(true); // For image loading
  const [slideDirection, setSlideDirection] = useState("left");
  const [isHovered, setIsHovered] = useState(false); // Track hover state

  // Hardcoded Product Review-Images URLs
  const images = [
    "https://i.ibb.co/Xf3XCsnT/DS-Pink.png",
    "https://i.ibb.co/F4FGJ283/DS-White-2.png",
    "https://i.ibb.co/2GSW7Mv/DS-White.png",
    "https://i.ibb.co/yF1mVWw2/DSi-Lime-Green.png",
    "https://i.ibb.co/v4x8qWjd/DSI-Navy-Blue.png",
    "https://i.ibb.co/HLvwfy3Q/DSi-Pink-2.png",
    "https://i.ibb.co/d0M0GZNf/DSI-PINK.png",
    "https://i.ibb.co/ksX7Mnq2/DSi-White-2.png",
    "https://i.ibb.co/kVWZCvx9/DSI-White.png",
    "https://i.ibb.co/cKw7ZXC7/DSi-XL-Anniversary.png",
    "https://i.ibb.co/0VqVwhC2/PSP.jpg",
    "https://i.ibb.co/nqyMYtgR/Rose-Gold-DS-Lite.jpg",
    "https://i.ibb.co/67yMvtCW/3DS.jpg",
    "https://i.ibb.co/JRSPbjJY/DS-Lite-Black-2.png",
    "https://i.ibb.co/xqK68BC1/DS-Lite-Black-3.png",
    "https://i.ibb.co/Pzrzv7jm/DS-Lite-Black.png",
    "https://i.ibb.co/fVkPyrjj/DS-Lite-Cyan.png",
    "https://i.ibb.co/d4s3qn9K/DS-Lite-Pink.png",
    "https://i.ibb.co/KjpMZ5hG/DS-Lite-Red.png",
    "https://i.ibb.co/qMY2vQ3j/DS-Lite-Silver-2.png",
    "https://i.ibb.co/KxhzNWTt/DS-Lite-Silver.png",
    "https://i.ibb.co/0RQXSj9Q/DS-Lite-White-2.png",
    "https://i.ibb.co/0VdffsGS/DS-Lite-White-3.png",
    "https://i.ibb.co/1tBRzMcq/DS-Lite-White.png",
  ];

  const openPopup = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const closePopup = () => setIsOpen(false);

  const navigateSlide = (direction) => {
    if (isLoading) return;
    setIsLoading(true);
    setIsImageLoading(true); // Reset image loading state for the new image
    setSlideDirection(direction);

    if (direction === "left") {
      setCurrentSlide((prev) => (prev + 1) % images.length);
    } else {
      setCurrentSlide((prev) => (prev - 1 + images.length) % images.length);
    }

    setTimeout(() => setIsLoading(false), 300);
  };

  const nextSlide = () => navigateSlide("left");
  const prevSlide = () => navigateSlide("right");

  const swipeHandlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // CSS for animations and mobile visibility
  const animationStyles = `
    @keyframes slideInLeft {
      from { transform: translateX(100%); }
      to { transform: translateX(0); }
    }
    @keyframes slideInRight {
      from { transform: translateX(-100%); }
      to { transform: translateX(0); }
    }
    @keyframes pulse {
      0% { transform: scale(1); }
      50% { transform: scale(1.1); }
      100% { transform: scale(1); }
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    .slide-animation {
      animation: ${
        slideDirection === "left" ? "slideInLeft" : "slideInRight"
      } 0.3s ease-out;
    }
    .arrow-button {
      display: none;
      animation: pulse 2s infinite;
    }
    @media (max-width: 768px) {
      .arrow-button {
        display: flex !important;
      }
    }
  `;

  return (
    <div>
      <style>{animationStyles}</style>
      <Button onClick={openPopup} /* ... your button styles ... */>
        Customer Reviews
      </Button>

      {isOpen && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            {...swipeHandlers}
            onMouseEnter={() => setIsHovered(true)} // Show arrows on hover
            onMouseLeave={() => setIsHovered(false)} // Hide arrows when not hovered
            style={{
              backgroundColor: "#FFF",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              position: "relative",
              maxWidth: "90vw",
              maxHeight: "90vh",
              overflow: "hidden",
            }}
          >
            {/* Left Arrow */}
            <div
              className="arrow-button"
              onClick={prevSlide}
              title="Previous Slide"
              style={{
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "50%",
                padding: "10px",
                display: isHovered ? "flex" : "none", // Show only on hover
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1001,
              }}
            >
              <FaChevronLeft size={24} color="#FFF" />
            </div>

            {/* Right Arrow */}
            <div
              className="arrow-button"
              onClick={nextSlide}
              title="Next Slide"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "50%",
                padding: "10px",
                display: isHovered ? "flex" : "none", // Show only on hover
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1001,
              }}
            >
              <FaChevronRight size={24} color="#FFF" />
            </div>

            {/* Image Container */}
            <div
              style={{
                overflow: "hidden",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "300px", // Ensure container has a minimum height
              }}
            >
              {/* Loading Spinner */}
              {isImageLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                  }}
                >
                  <ImSpinner8
                    size={32}
                    color="#007BFF"
                    style={{ animation: "spin 1s linear infinite" }}
                  />
                </div>
              )}

              {/* Image */}
              <img
                key={currentSlide}
                src={images[currentSlide]}
                alt={`Slide ${currentSlide + 1}`}
                className="slide-animation"
                onLoad={() => setIsImageLoading(false)} // Set loading to false when image loads
                style={{
                  maxWidth: "100%",
                  maxHeight: "80vh",
                  borderRadius: "5px",
                  opacity: isImageLoading ? 0 : 1, // Hide image until loaded
                  transition: "opacity 0.3s ease-in-out",
                }}
              />
            </div>

            {/* Navigation buttons */}
            <div style={{ marginTop: "10px" }}>
              <Button
                onClick={prevSlide}
                disabled={isLoading}
                style={{
                  padding: "10px 20px",
                  margin: "0 5px",
                  fontSize: "16px",
                  cursor: isLoading ? "not-allowed" : "pointer",
                  backgroundColor: isLoading ? "#CCC" : "#007BFF",
                  color: "#FFF",
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                Previous
              </Button>
              <Button
                onClick={nextSlide}
                disabled={isLoading}
                style={{
                  padding: "10px 20px",
                  margin: "0 5px",
                  fontSize: "16px",
                  cursor: isLoading ? "not-allowed" : "pointer",
                  backgroundColor: isLoading ? "#CCC" : "#007BFF",
                  color: "#FFF",
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                Next
              </Button>
            </div>

            <Button
              onClick={closePopup}
              style={{
                marginTop: "10px",
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
                backgroundColor: "#DC3545",
                color: "#FFF",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageSliderPopup;
